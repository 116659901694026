import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../../../services/navbar.service';
import { ForgotpasswordService } from '../../../services/forgotpassword.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    linkSent = false;
	  emailSentMessage: any;
    forgotPasswordForm!: UntypedFormGroup;

    constructor(
        private router: Router,
        private nav: NavbarService,
        private forgotpasswordService:ForgotpasswordService
    ) {}

    ngOnInit(): void {
        this.nav.hide();
        this.forgotPasswordForm = new UntypedFormGroup({
          "email": new UntypedFormControl(null, [Validators.required, Validators.pattern(/.+@.+\..+/)]),
        });
    }

    forgotPassword() {
        const emailId = {"email": this.forgotPasswordForm.controls.email.value};
        this.forgotpasswordService.forgotPassword(emailId).subscribe( (result) => {
            if(result.message){
				        this.emailSentMessage = result.message;
            	  this.linkSent = true;
            }
        });
    }

    returnToLogin() {
        this.router.navigate(['/login']);
    }
}
