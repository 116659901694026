import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
const routes: Routes = [
    {
      path: 'admin',
      loadChildren: () => import('./modules/administrator/administrator.module').then(m => m.AdministratorModule),
    },
    {
      path: 'user',
      loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    },
    {
      path: '',
      pathMatch: 'full',
      redirectTo: '/login'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})

export class AppRoutingModule { }
