<div class="container">
  <div class="row">
      <div class="col-md-4 offset-md-4">
          <div class="forget-form bg-light mt-4 p-4">
              <div>
                  <h5>Change Password</h5>
                  <form [formGroup]="changePasswordForm" class="form-inline">
                      <div class="row">
                          <div class="form-group input-group-sm">
                              <label for="old_password" class="col-form-label-sm">Old Password:</label>
                              <input type="password" class="form-control"  placeholder="Enter old password" formControlName="old_password" name="old_password" id="old_password">
                                <div *ngIf="formFields.old_password.touched && formFields.old_password.invalid" class="error col-form-label-sm">
                                    <div *ngIf="formFields.old_password.errors && formFields.old_password.errors.required">Old password is required</div>
                                </div>

                              <label for="new_password" class="col-form-label-sm">New Password:</label>
                              <input type="password" class="form-control"  placeholder="Enter new password" formControlName="new_password" name="new_password" id="new_password">
                              <div *ngIf="formFields.new_password.touched && formFields.new_password.invalid" class="error col-form-label-sm">
                                    <div *ngIf="formFields.new_password.errors && formFields.new_password.errors.required">New password is required</div>
                              </div>

                              <label for="confirm_new_password" class="col-form-label-sm">Confirm New Password:</label>
                              <input type="password" class="form-control"  placeholder="Enter confirm password" formControlName="confirm_new_password" name="confirm_new_password" id="confirm_new_password">
                                <div *ngIf="formFields.confirm_new_password.touched && formFields.confirm_new_password.invalid" class="error col-form-label-sm">
                                    <div *ngIf="formFields.confirm_new_password.errors?.required">Confirm password is required</div>
                                    <div *ngIf="formFields.confirm_new_password.errors?.passwordMismatch">Password did not match</div>
                                </div>

                          </div>

                          <div class="col-12 mt-4">
                              <button type="button" [disabled]="!changePasswordForm.valid" class="btn btn-primary float-end btn-sm me-2" (click)="changePassword()">Change Password</button>
                          </div>

                      </div>
                  </form>
              </div>

          </div>
      </div>
  </div>
</div>
