import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()

export class AuthService {

    constructor(private http: HttpClient) {}

    isLoggedIn() : boolean {
        return  localStorage.getItem('token') != null;
    }

    setJWTToken(token: any): Promise<any> {
        return Promise.resolve().then(function () {
            localStorage.setItem('token', token);
        });
    }

    getJWTToken() {
        return localStorage.getItem('token');
    }

    setAccessToken(token: any) {
        localStorage.setItem('token', token);
    }

    getAccessToken() {
        return localStorage.getItem('access-token');
    }

    removeToken() {
      localStorage.removeItem("token");
    }

    checkUser() {
      Auth.currentAuthenticatedUser()
        .then(user => console.log({ user }))
        .catch(err => console.log(err))
    }

    getLogins(data: any): Observable<any> {
      return this.http.post(environment.api_url + '/login', data);
    }
}
