import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { NavbarService } from '../../../services/navbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotpasswordService } from 'src/app/services/forgotpassword.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm!: UntypedFormGroup;
  email: any;

  constructor(
    private router: Router,
    private nav: NavbarService,
    private actRoute: ActivatedRoute,
    private forgotpasswordService:ForgotpasswordService,
    private toastr: ToastrService) {
    this.email = this.actRoute.snapshot.params.email;
  }

  ngOnInit(): void {
    this.nav.hide();
    this.setPasswordForm = new UntypedFormGroup({
			"code": new UntypedFormControl(null, [Validators.required]),
      "password": new UntypedFormControl(null, [Validators.required]),
			"confirmPassword": new UntypedFormControl(null, [Validators.required])
	  }, {
      validators: this.passwordMatchValidator
    });
  }

  onSubmit(){ }

  get setPwdForm() { return this.setPasswordForm.controls; }

  resetForgotPassword() {
    let data = {
        email: this.email,
        code: this.setPwdForm.code.value,
        password: this.setPwdForm.password.value,
    }

    this.forgotpasswordService.resetForgotPassword(data).subscribe( (result) => {
        // Show success message and return to login page
        this.toastr.success('Password has been changed successfully! Please wait a while you will automatically redirect to login page.', '', { timeOut: 5000});
        this.returnToLogin();
      }, error => {
        this.toastr.error(error.error.error, '', { timeOut: 3000});
        console.log(error);
    });
}

returnToLogin() {
  this.router.navigate(['/login']);
}

passwordMatchValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  if (password && confirmPassword && password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ 'passwordMismatch': true });
    return { 'passwordMismatch': true };
  } else {
    return null;
  }
}

}
