<div class="container">
    <div class="row">
        <div class="col-md-5 offset-md-3">
            <div class="forget-form bg-light mt-4 p-4">
                <div *ngIf="!linkSent">
                    <form class="form-inline" [formGroup]="forgotPasswordForm">
                        <h5>Forgot Password</h5>
                        <div class="row mb-2">
                            <div class="form-group input-group-sm fix-height">
                                <label for="email" class="col-form-label-sm">Email Address:</label>
                                <input type="email" class="form-control"  placeholder="Enter Email Address" formControlName="email" id="email" [email]="true">
                                <div *ngIf="forgotPasswordForm.controls.email.touched && forgotPasswordForm.controls.email.invalid" class="error col-form-label-sm">
                                  <div *ngIf="forgotPasswordForm.controls.email.errors && forgotPasswordForm.controls.email.errors.required">Email is required</div>
                                  <div *ngIf="forgotPasswordForm.controls.email.errors && forgotPasswordForm.controls.email.errors.email">Please enter valid Email</div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                                <button type="button" class="btn btn-secondary float-end mr-1 btn-sm" (click)="returnToLogin()">Cancel</button>
                                <button type="button" class="btn btn-primary float-end btn-sm me-2" (click)="forgotPassword()" [disabled]="!forgotPasswordForm.valid">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="p-4 mb-4" *ngIf="linkSent">
                    <div>
                      <p>A reset password request is being processed.</p>
                      <p>This can take up to 15 minutes to arrive. If you do not receive an email, please check your Junk/Spam folder.</p>
                      <p>Invalid emails or emails not associated with an account will not receive an email</p>
                    </div>
                    <button type="button" class="btn btn-primary float-end btn-sm mt-3 mb-3" (click)="returnToLogin()">Return to Login</button>
                </div>
            </div>
        </div>
    </div>
</div>
