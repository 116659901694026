<div class="container">
    <div class="row">
        <div class="col-md-4 offset-md-4">
            <div class="setPassword-form bg-light mt-4 p-4">
                <form class="form-inline"  [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
                    <h5>Reset Password</h5>
                    <div>We have sent a password reset code by email. Enter it bellow to reset your password.</div>
                    <div class="row mb-2">
                        <div class="form-group input-group-sm">
                            <label for="code" class="col-form-label-sm">Code:</label>
                            <input type="text" formControlName="code" class="form-control" id="code" placeholder="Enter code" name="code">
                            <div *ngIf="setPwdForm.code.touched && setPwdForm.code.invalid" class="error col-form-label-sm">
                                <div *ngIf="setPwdForm.code.errors && setPwdForm.code.errors.required">Code is required</div>
                                <div *ngIf="setPwdForm.code.errors && setPwdForm.code.errors.code">Please enter valid Code</div>
                            </div>
                        </div>
                        <div class="form-group input-group-sm">
                          <label for="password" class="col-form-label-sm">Password:</label>
                          <input type="password" formControlName="password" class="form-control" id="password" placeholder="Enter password" name="password">
                          <div *ngIf="setPwdForm.password.touched && setPwdForm.password.invalid" class="error col-form-label-sm">
                              <div *ngIf="setPwdForm.password.errors && setPwdForm.password.errors.required">Password is required</div>
                              <div *ngIf="setPwdForm.password.errors && setPwdForm.password.errors.password">Please enter valid password</div>
                          </div>
                        </div>
                        <div class="form-group input-group-sm">
                            <label for="verifyPassword" class="col-form-label-sm">Confirm Password:</label>
                            <input type="password" formControlName="confirmPassword" class="form-control" id="confirmPassword" placeholder="Enter confirm password" name="confirmPassword">
                            <div *ngIf="setPwdForm.confirmPassword.touched && setPwdForm.confirmPassword.invalid" class="error col-form-label-sm">
                                <div *ngIf="setPwdForm.confirmPassword.errors?.required">Confirm password is required</div>
                                <div *ngIf="setPwdForm.confirmPassword.errors?.passwordMismatch">Password did not match</div>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <button type="button" class="btn btn-secondary float-end mr-1 btn-sm" (click)="returnToLogin()">Cancel</button>
                            <button type="button" class="btn btn-primary float-end btn-sm me-2" [disabled]="!setPasswordForm.valid" (click)="resetForgotPassword()">Change Password</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
