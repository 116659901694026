import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { EquipmentComponent } from './component/equipment/equipment.component';
// import { ReportsComponent } from './component/reports/reports.component';
// import { UsersComponent } from './component/users/users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { RequestReceivedComponent } from './component/request-received/request-received.component';

// import { DialogAddScanner } from './component/equipment-new/AddScanner/dialog-add-scanner';
// import { DialogAddTransducer} from './component/equipment-new/AddTransducer/dialog-add-transducer';

// Interceptors
import { AuthTokenInterceptor } from './interceptor/AuthTokenInterceptor';

// AWS
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Fontawesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// toster masange
import { ToastrModule } from 'ngx-toastr';

// Angular material
import { AngularMaterialModule } from './modules/angular-material/angular-material.module';
// import { NavbarComponent } from './component/navbar/navbar.component';
import { DatePipe } from '@angular/common';

// line chart
import { NgChartsModule } from 'ng2-charts';
import { AuthService } from './services/auth.service';

// Authentication module
import { AuthenticationModule } from './modules/authentication/authentication.module';

// Review module
// import { ReviewModule } from './modules/review/review.module';
// import { AnalysisComponent } from './component/analysis/analysis.component';

// import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
// import { BasicEquipmentEvaluationComponent } from './component/equipment/basic-equipment-evaluation/basic-equipment-evaluation.component';
// import { ScannerEquipmentLayoutComponent } from './component/equipment/basic-equipment-evaluation/scanner-equipment-layout/scanner-equipment-layout.component';
// import { TransducerEquipmentLayoutComponent } from './component/equipment/basic-equipment-evaluation/transducer-equipment-layout/transducer-equipment-layout.component';
// import { EvaluationsComponent } from './component/evaluations/evaluations.component';
// import { EvalutionReviewComponent } from './component/evaluations/evalution-review/evalution-review.component';
// import { TransducerEvaluationReviewComponent } from './component/evaluations/evalution-review/transducer-evaluation-review/transducer-evaluation-review.component';
// import { ScannerEvaluationReviewComponent } from './component/evaluations/evalution-review/scanner-evaluation-review/scanner-evaluation-review.component';
// import { EquipmentNewComponent } from './component/equipment-new/equipment-new.component';
// import { DialogEditScanner } from './component/equipment-new/EditScanner/dialog-edit-scanner';
// import { DialogDisplayScanner } from './component/equipment-new/DisplayScannerDetails/dialog-display-scanner';
// import { DialogDisplayTransducer } from './component/equipment-new/DisplayTransducerDetails/dialog-display-transducer';
// import { DialogEditTransducer } from './component/equipment-new/EditTransducer/dialog-edit-transducer';
// import { AddUserComponent } from './component/users/add-user/add-user.component';
// import { CreateAccountComponent } from './component/create-account/create-account.component';
// import { AccountSettingsComponent } from './component/account-settings/account-settings.component';
// import { UserSettingsComponent } from './component/users/user-settings/user-settings.component';
// import { FacilityManagementComponent } from './component/facility-management/facility-management.component';
// import { AddFacilityComponent } from './component/facility-management/add-facility/add-facility.component';
// import { EditFacilityComponent } from './component/facility-management/edit-facility/edit-facility.component';
// import { AdministratorModule } from './modules/administrator/administrator.module';

// var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;

@NgModule({
  declarations: [
    AppComponent,
    // CanvasJSChart,
    // EquipmentComponent,
    // ReportsComponent,
    // UsersComponent,
    // RequestReceivedComponent,
    // NavbarComponent,
    // AnalysisComponent,
    // BasicEquipmentEvaluationComponent,
    // ScannerEquipmentLayoutComponent,
    // TransducerEquipmentLayoutComponent,
    // EvaluationsComponent,
    // EvalutionReviewComponent,
    // TransducerEvaluationReviewComponent,
    // ScannerEvaluationReviewComponent,
    // EquipmentNewComponent,
    // DialogAddScanner,
    // DialogAddTransducer,
    // DialogEditScanner,
    // DialogDisplayScanner,
    // DialogDisplayTransducer,
    // DialogEditTransducer,
    // AddUserComponent,
    // CreateAccountComponent,
    // AccountSettingsComponent,
    // UserSettingsComponent,
    // FacilityManagementComponent,
    // AddFacilityComponent,
    // EditFacilityComponent
  ],
  imports: [
    AuthenticationModule,
    // AdministratorModule,
    CommonModule,
    // ReviewModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgChartsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    AuthService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true, deps: [AuthService]}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
