import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ForgotpasswordService } from 'src/app/services/forgotpassword.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

    changePasswordForm!: UntypedFormGroup;

    constructor(
        private router: Router,
        private nav: NavbarService,
        private toastr: ToastrService,
        private forgotpasswordService:ForgotpasswordService
    ) { }

    ngOnInit(): void {
        this.changePasswordForm = new UntypedFormGroup({
            "old_password": new UntypedFormControl(null, [Validators.required]),
            "new_password": new UntypedFormControl(null, [Validators.required]),
            "confirm_new_password": new UntypedFormControl(null, [Validators.required])
        }, {
          validators: this.passwordMatchValidator
        });
    }

    get formFields() { return this.changePasswordForm.controls; }


    changePassword() {
        let data = {
            "old_password": this.formFields.old_password.value,
            "new_password": this.formFields.new_password.value
        }

        this.forgotpasswordService.changePassword(data).subscribe( (result) => {
            if(result){
                console.log(result);
                this.toastr.success('Password has been changed successfully!', '', { timeOut: 3000});
            }
        }, error => {
            this.toastr.error(error.error.error, '', { timeOut: 1500});
            console.log(error.error.error);
      });
    }

    returnToLogin() {
        this.router.navigate(['/login']);
    }

    passwordMatchValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.get('new_password');
      const confirmPassword = control.get('confirm_new_password');

      if (password && confirmPassword && password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ 'passwordMismatch': true });
        return { 'passwordMismatch': true };
      } else {
        return null;
      }
    }
}
