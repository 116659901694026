<div class="container">
    <div class="row">
        <div class="col-md-4 offset-md-4">
            <div class="login-form bg-light mt-4 p-4">
                <form class="row g-3" [formGroup]="loginForm" (ngSubmit)="doLogin()">
                    <h5>Login</h5>
                    <div class="col-12">
                        <label>Username</label>
                        <input type="text" name="username" class="form-control" formControlName ="username" autofocus>
                        <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.invalid" class="error col-form-label-sm">
                            <div *ngIf="loginForm.controls.username.errors && loginForm.controls.username.errors.required">Username is required</div>
                            <div *ngIf="loginForm.controls.username.errors && loginForm.controls.username.errors.username">Please enter valid username</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <label>Password</label>
                        <input type="password" name="password" class="form-control" formControlName ="password">
                        <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid" class="error col-form-label-sm">
                            <div *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="rememberMe">
                            <label class="form-check-label" for="rememberMe"> Remember me</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary float-end btn-sm" [disabled]="!loginForm.valid || showLoading == true">Login</button>
                    </div>
                    <div class="text-center" *ngIf="showLoading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </form>
                <hr class="mt-4">
                <div class="col-12">
                    <p class="text-center mb-0"><a [routerLink]="['/forgot-password']">Forgot Password</a></p>
                </div>
            </div>
        </div>
    </div>
</div>