import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../../../services/navbar.service';
import { Auth } from '@aws-amplify/auth';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	usernameValue: any = '';
	passwordValue: any = '';
	loginForm = new UntypedFormGroup({
		"username": new UntypedFormControl(null, [Validators.required]),
		"password": new UntypedFormControl(null, [Validators.required])
	});
	showLoading = false;

  	constructor(private nav: NavbarService, private auth:AuthService, private router: Router, private toastr: ToastrService) {}

	ngOnInit(): void {
		this.nav.hide();
	}

	// Function to login user
	doLogin() {
		this.usernameValue = this.loginForm.controls.username.value;
		this.passwordValue = this.loginForm.controls.password.value;
    let data = {
      "username": this.loginForm.controls.username.value,
	    "password": this.loginForm.controls.password.value
    }
		if (this.usernameValue && this.passwordValue) {
			this.showLoading = true;
      this.auth.getLogins(data).subscribe((logins) => {
        console.log(logins);
        this.auth.setJWTToken(logins.Token).then(data => {
          switch(logins.Role) {
            case 'User':
              this.router.navigate(['/user/equipment']);
            break;
            case 'MirionAdministrator':
              this.router.navigate(['/admin/subscription-management']);
            break;
          }
        });
      });
		}
	}

}
